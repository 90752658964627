import { UserBasedPagesApi } from '../api/UserBasedPagesApi';
import store from '../../store';
import { UserPage } from '../../components/common/layoutModel';

const loadMenuService = {

    async loadUserBasedPages() {
        await UserBasedPagesApi.GetUserBasedPages().then(response=>{
            store.dispatch('setUserBasedPages', response as UserPage[]);
        });
    }

}

export default loadMenuService;