const en = {
    //Common
    "update": "Update",
    "save": "Save",
    "remove": "Remove",
    "addNew": "Add New",
    "add": "Add",
    "ok": "OK",
    "cancel": "Cancel",
    "close": "Close",
    "actions": "Actions",
    "all": "All",
    "edit": "Edit",
    "delete": "Delete",
    "selectAll": "Select All",
    "swedish": "Swedish",
    "english": "English",
    "yes": "Yes",
    "no": "No",
    "password": "Password",
    "confirmPassword": "Confirm Password",
    "msgSureWantToDelete": "Are you sure want to delete?",
    "msgSave": "Save information successfully!",
    "msgUpdate": "Update information successfully!",
    "msgDelete": "Delete information successfully!",
    "msgUnauthorized": "You're Unauthorized! Please contract to the administrator.",
    "msgForbidden": "Forbidden!!",
    "msgNotFound": "Not Found!!",
    "msgServerError": "Server Error!!",
    "confirmationDialog":"Confirmation Dialog",
    "confirmDeleteContent":"Are you sure want to delete?",
    "msgProvideAllData": "Please provide all data!",
    "home":"Home",
    "templates":"Templates",
    "roles":"Roles",
    "users":"Users",
    "pages":"Pages",
    "page":"Page",
    "msgSelectParent": "Select Parent",
    "title": "Title",
    "icon": "Icon",
    "ordinal": "Ordinal",
    "parent": "Parent",
    "active": "Active",
    "active (manual override)": "Active (Manual Override)",
    "organizations": "Organizations",
    "cost center permission": "Cost Center Permission",
    "access management": "Access Management",
    "add new": "Add New",
    "role name": "Role Name",
    "msgSelectRole": "Select Roles",
    "role display name": "Role Display Name",
    "permission scope": "Permission Scope",
    "user": "User",
    "full": "Full",
    "permission": "Permission",
    "permissions": "Permissions",
    "first": "First",
    "last": "Last",
    "name": "Name",
    "email": "Email",
    "select": "Select",
    "welcome to sortera": "Welcome to Sortera",
    "Company Name": "Company Name",
    "Company Number": "Company Number",
    "Profit Center Name": "Profit Center Name",
    "Profit Center Code": "Profit Center Code",
    "Business Unit Name": "Business Unit Name",
    "Division": "Division",
    "Business Area Code": "Business Area Code",
    "Business Area Name": "Business Area Name",
    "Country": "Country",
    "Source": "Source",
    "Profit Center Key": "Profit Center Key",
    "expand all": "Expand All",
    "collapse all": "Collapse All",
    "organisation mapping": "Organisation Mapping",
    "company": "Company",
    "object": "Object",
    "system": "System",
    "source": "Source",
    "organisations": "Organisations",
    "organisation": "Organisation",
    "destination company": "Destination Company",
    "destination profit center": "Destination Profit Center",
    "destination business area": "Destination Business Area",
    "select destination company": "Select Destination Company",
    "select destination profit center": "Select Destination Profit Center",
    "select destination business area": "Select destination Business Area",
    "mapping": "Mapping",
    "master table": "Master Table",
    "hr": "HR",
    "paycodes": "Paycodes",
    "time codes":"Time Codes",
    "kpi": "KPI",
    "sales": "Sales",
    "sales stage probability": "Sales Stage Probability",
    "activity budget": "Activity Budget",
    "sales budget": "Sales Budget",
    "operations": "Operations",
    "vehicle efficiency": "Vehicle Efficiency",
    "select year": "Select Year",
    "select company": "Select Company",
    "select division": "Select Division",
    "division budget": "Division Budget",
    "sales representative budget": "Sales Representative Budget",
    "Company": "Company",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "Division Budget Saved Successfully": "Division Budget Saved Successfully",
    "Invalid Inputs. Negative values not allowed": "Invalid Inputs. Negative values not allowed",
    "Representative Budget Saved Successfully": "Representative Budget Saved Successfully",
    "select representative": "Select Representative",
    "representative": "Representative",
    "activity type": "Activity Type",
    "customer type": "Customer Type",
    "Acitivity Budget Saved Successfully": "Acitivity Budget Saved Successfully",
    "Stage Probabilities Saved Successfully": "Stage Probabilities Saved Successfully",
    "stage": "Stage",
    "probability": "Probability",
    "probabilities": "Probabilities",
    "time code": "Time Code",
    "time code name": "Time Code Name",
    "absence time": "Absence Time",
    "available time": "Available Time",
    "include in FTE": "Include In FTE",
    "Time Codes Saved Successfully": "Time Codes Saved Successfully",
    "company name": "Company Name",
    "company number": "Company Number",
    "paycode": "Paycode",
    "paycode name": "Paycode Name",
    "gross salary": "Gross Salary",
    "include in cost": "Include In Cost",
    "benefit": "Benefit",
    "properties": "Properties",
    "property": "Property",
    "total items": "Total Items",
    "select resource category": "Select Resource Category",
    "resource category code": "Resource Category Code",
    "resource category name": "Resource Category Name",
    "resource type": "Resource Type"
}

export default en;