import { AppConstants } from "@/services/shared/appConstant";
import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    tenant: AppConstants.TenantId,
    clientId: AppConstants.ClientId,
    authority: AppConstants.Authority,
    redirectUri:  AppConstants.CLIENT_URL, 
    postLogoutRedirectUri: AppConstants.PostLogoutRedirectUri, 
    scopes: AppConstants.Scopes,
    navigateToRequestUrl: false
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    allowRedirectInIframe: true,
  }
}

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: AppConstants.Scopes,
};