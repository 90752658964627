import axios from "axios";
import { ApiRoutes, getHeaders, } from "./apiRoutes";
import httpClient from "../common/httpClient";

export  class BasicAuthApi{
    static BasicAuthLogin(username: string, password: string):Promise<any>{
        return httpClient.post(ApiRoutes.basicAuthApi.BasicAuthLogin, {
            username : username,
            password : password
        })
    }

    static ChangePass(password: string, confirmPassword: string):Promise<any>{
        return httpClient.post(ApiRoutes.basicAuthApi.ChangePassword,{
            password : password,
            confirmPassword : confirmPassword
        },getHeaders())
    }
}