<template>
    <div class="wrapper" v-if="isSettingPage() && isAuthTypeSet">
      <LayoutComponent />
      <div class="content-wrapper" style="padding:3px;" >
        <router-view />
      </div>
  
      <footer class="main-footer">
        <div class="float-right d-none d-sm-block">
          <b>Version</b> 1.0.0
        </div>
        <strong>Copyright &copy;<a href="#">Numberskills</a>.</strong> All rights reserved.
      </footer>
    </div>
    <div v-else-if="isAuthTypeSet" >
      <router-view />
    </div>
    <div class="modal fade" id="LoginMethodModal" Page="dialog">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold; margin: 0px auto;" >Select Login Method</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                      <div class="col-md-3">
                      </div>
                      <div class="col-md-3">
                        <button v-on:click="loginWithCustomAuth" class="btn btn-sm btn-info">
                          Custom Login
                        </button>
                      </div>
                      <div class="col-md-3">
                        <button v-on:click="loginWithAzure" class="btn btn-sm btn-info">
                            Azure Login
                        </button>
                      </div>
                      <div class="col-md-3">
                      </div>
                    </div>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <div class="modal fade" id="CustomLoginModal" Page="dialog">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold; margin: 0px auto;" >Login</h4>
                </div>
                <div class="modal-body">
                    <div class="row" v-if="errorMessage" >
                      <div class="col-md-12" v-if="errorMessage" >
                        <div class="alert alert-danger" role="alert">
                          {{ errorMessage }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                      </div>
                      <div class="col-md-6">
                          <div class="form-group">
                              <label>Username</label>
                              <input type="text" class="form-control input-sm rounded-0"  v-model="username"
                                 />
                          </div>
                      </div>
                      <div class="col-md-3">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                      </div>
                      <div class="col-md-6">
                          <div class="form-group">
                              <label>Password</label>
                              <input type="password" class="form-control input-sm rounded-0"  v-model="password"
                                   />
                          </div>
                      </div>
                      <div class="col-md-3">
                      </div>
                    </div>                   
                    <div class="row" >
                      <div class="col-md-5" >
                      </div>                    
                      <div class="col-md-2" >
                        <button v-on:click="logIn" class="btn btn-sm btn-info">
                            Login
                        </button>
                      </div>
                    </div>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
  </template>
  <script lang="ts">
  import { defineComponent } from 'vue';
  import LayoutComponent from './components/common/LayoutComponent.vue';
  import authService from "./services/common/auth-service";
  import { BasicAuthApi } from './services/api/BasicAuthApi';
  import store from './store';
  import router from './router/router';

  export default defineComponent({
    components:{
      LayoutComponent
    },
    data(){

      return {
        errorMessage : "",
        username: "",
        password: "",
        isAuthTypeSet : false
      }

    },
    mounted() {
      this.isAuthTypeSet = authService.getAuthType() && 
                           authService.getAuthType() != 'basicUnconfirmed' ? true : false;

    },
    methods:{
      isSettingPage(){
        return this.$route.path != '/register' 
        && this.$route.path != '/login'  
        && this.$route.path != '/forget-password';
      },
      loginWithCustomAuth(){
        authService.setAuthType("basicUnconfirmed");
        window.location.reload();
      },
      loginWithAzure(){
        authService.setAuthType("azure");
        window.location.reload();
      },
      validateForm(){
        if(!this.username){
          this.errorMessage = "Enter a valid Username.";
        }
        else if(!this.password){
          this.errorMessage = "Enter a valid Password.";
        }
        else{
          this.errorMessage = "";
        }
      },
      logIn(){

        this.validateForm();

        if(!this.errorMessage){

          BasicAuthApi.BasicAuthLogin(this.username, this.password).then(
            resp => {
              
                 if(resp.data.token){

                    authService.setAuthType('basic');
                    localStorage.setItem('accessToken', resp.data.token);
                    localStorage.setItem('user', resp.data.email);
                    store.dispatch('getUser', resp.data.email);
                    store.dispatch('setAccessToken', resp.data.token);
                    window.location.reload();

                 }
                 else{
                   this.errorMessage = "Invalid Username or Password!!"
                 }

            }
          );

        }

      }
    }
  });
  </script>

<style>
    @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
    @import '../node_modules/@syncfusion/ej2-base/styles/material.css';
    @import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
    @import "../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-filemanager/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-notifications/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";

    .version-section {
        padding-right: 0.5em;
        display: flex;
        justify-content: flex-end;
    }

    .btn-success {
      background-color: white !important;
      border: 3px solid #76bc21 !important;
      border-radius: 5px !important;
      color: #76bc21 !important;
    }

    .btn-danger {
      background-color: white !important;
      border: 3px solid #c82333 !important;
      border-radius: 5px !important;
      color: #c82333 !important;
    }

</style>
