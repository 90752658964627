<template>
    <nav class="main-header navbar navbar-expand" v-bind:class="{'navbar-yellow' : isTest, 'navbar-green' : !isTest}">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
            </li>
        </ul>
        <ul class="navbar-nav navbar-right ml-auto align-items-center">
            <!-- Navbar Search -->
            <!-- <li class="nav-item">
                <a class="nav-link" data-widget="navbar-search" href="#" role="button">
                <i class="fas fa-search"></i>
                </a>
                <div class="navbar-search-block">
                <form class="form-inline">
                    <div class="input-group input-group-sm">
                    <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
                    <div class="input-group-append">
                        <button class="btn btn-navbar" type="submit">
                        <i class="fas fa-search"></i>
                        </button>
                        <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                        <i class="fas fa-times"></i>
                        </button>
                    </div>
                    </div>
                </form>
                </div>
            </li>-->

            <!-- Messages Dropdown Menu -->
             <!--<li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                <i class="far fa-comments"></i>
                <span class="badge badge-danger navbar-badge">3</span>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <a href="#" class="dropdown-item">
                    <div class="media">
                    <img src="../../../node_modules/admin-lte/dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                    <div class="media-body">
                        <h3 class="dropdown-item-title">
                        Brad Diesel
                        <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                        </h3>
                        <p class="text-sm">Call me whenever you can...</p>
                        <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                    </div>
                    </div>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                    <div class="media">
                    <img src="../../../node_modules/admin-lte/dist/img/user8-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                    <div class="media-body">
                        <h3 class="dropdown-item-title">
                        John Pierce
                        <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                        </h3>
                        <p class="text-sm">I got your message bro</p>
                        <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                    </div>
                    </div>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                    <div class="media">
                    <img src="../../../node_modules/admin-lte/dist/img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                    <div class="media-body">
                        <h3 class="dropdown-item-title">
                        Nora Silvester
                        <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                        </h3>
                        <p class="text-sm">The subject goes here</p>
                        <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                    </div>
                    </div>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
                </div>
            </li> -->
            <!-- Notifications Dropdown Menu -->
             <!--<li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                <i class="far fa-bell"></i>
                <span class="badge badge-warning navbar-badge">15</span>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span class="dropdown-item dropdown-header">15 Notifications</span>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                    <i class="fas fa-envelope mr-2"></i> 4 new messages
                    <span class="float-right text-muted text-sm">3 mins</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                    <i class="fas fa-users mr-2"></i> 8 friend requests
                    <span class="float-right text-muted text-sm">12 hours</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                    <i class="fas fa-file mr-2"></i> 3 new reports
                    <span class="float-right text-muted text-sm">2 days</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
                </div>
            </li> -->
            <li class="nav-item">
                <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                <i class="fas fa-expand-arrows-alt"></i>
                </a>
            </li>            
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    <img class='lang-flag' v-if="props.multilang.en" src='@/assets/icons/EN.svg' />
                    <img class='lang-flag' v-if="props.multilang.se" src='@/assets/icons/SE.svg' />
                    {{ props.language }}
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item" @click="languageChangeEvent('en')">
                        <img class='lang-flag' src='@/assets/icons/EN.svg' /> English
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item" @click="languageChangeEvent('se')">
                        <img class='lang-flag' src='@/assets/icons/SE.svg' /> Swedish
                    </a>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-user-circle"></i> {{ user }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">

                    <a class="dropdown-item" v-if="isBasicAuthEnabled" @click="changePasswordOpen()">
                        Change Password
                    </a>

                    <div class="dropdown-divider"></div>

                    <a class="dropdown-item" @click="logOut()" style="cursor: pointer;" title="Logout">
                        <i class="fas fa-sign-out-alt"></i> Logout
                    </a>

                </div>
            </li>
        </ul>
    </nav>
    <div class="modal fade" id="ChangePasswordModal" Page="dialog">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" >Change Your Password</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" v-if="errorMessage" >
                      <div class="col-md-12" v-if="errorMessage" >
                        <div class="alert alert-danger" role="alert">
                          {{ errorMessage }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2">
                      </div>
                      <div class="col-md-8">
                          <div class="form-group">
                              <label>Password</label>
                              <input type="password" class="form-control input-sm rounded-0"  v-model="password"
                                   />
                          </div>
                      </div>
                      <div class="col-md-2">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2">
                      </div>
                      <div class="col-md-8">
                          <div class="form-group">
                              <label>Confirm Password</label>
                              <input type="password" class="form-control input-sm rounded-0" v-model="confirmPassword"
                                   />
                          </div>
                      </div>
                      <div class="col-md-2">
                      </div>
                    </div>                   
                    <div class="row" >
                      <div class="col-md-4" >
                      </div>                    
                      <div class="col-md-4" >
                        <button v-on:click="changePass" class="btn btn-sm btn-info">
                            Change Password
                        </button>
                      </div>
                    </div>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <ejs-dialog v-if='isModal' :header="dialogue" :buttons="buttons" :content="content" width="20%" ></ejs-dialog>
</template>
<script lang="ts">

import router from '@/router/router';
import authService from '@/services/common/auth-service';
import { defineComponent } from 'vue';
import store from '@/store'
import _ from 'lodash';
import helper from '@/services/common/helper';
import modal from '../common/modalManage';
import { BasicAuthApi } from '@/services/api/BasicAuthApi';
import { AppConstants } from '@/services/shared/appConstant';
import { DialogComponent } from '@syncfusion/ej2-vue-popups';


export default defineComponent({
    components:{
        "ejs-dialog": DialogComponent,
    },
    props: {
        multilang: {
            type: Object,
            default: () => ({ se: false, en: false }),
        },
        language: String
    },
    emits: ['click'],
    setup(props, ctx) {
        function languageChangeEvent(code: string) {
            ctx.emit('click', code);
        }
        return {
            props, ctx, languageChangeEvent,
        }
    },
    data() {
        return {
            isTest: false,
            isModal: false,
            dialogue: "",
            content: "",
            buttons: [
              { click: this.hideModal, buttonModel: { content: 'OK', isPrimary: true } },
              { click: this.hideModal, buttonModel: { content: 'Cancel' }}
            ],
            isBasicAuthEnabled: false,
            errorMessage: "",
            password: "",
            confirmPassword: ""
        }
    },
    computed: {
        user() {
            return store.state.user;
        },
    },
    async mounted() {
        await this.initApp();
        this.isBasicAuthEnabled = AppConstants.BasicLoginEnabled;
        this.isTest = location.href.includes('localhost') || location.href.includes('wonderful-sand') || location.href.includes('-test');
    },
    methods: {
        hideModal(){
            this.isModal = false; 
        },
        async initApp() {
            const user = localStorage.getItem('user');
            store.dispatch('getUser', user);
        },
        async logOut() {
            await authService.logout();
            router.push('/');
        },
        changePasswordOpen(){
            modal.Open('ChangePasswordModal');
        },
        close(){
            modal.Close('ChangePasswordModal');
        },
        buildKey(inputString: string) {
            return helper.toLowerToUpperCase(inputString);
        },
        validateForm(){
        if(!this.password){
          this.errorMessage = "Enter a Password.";
        }
        else if(!this.confirmPassword){
          this.errorMessage = "Please confirm Password.";
        }
        else if(this.password !== this.confirmPassword){
          this.errorMessage = "Password and Confirmed Password did not matched.";
        }
        else{
          this.errorMessage = "";
        }
      },
      async changePass() {

        this.validateForm();

        if(!this.errorMessage){

            await BasicAuthApi.ChangePass(this.password, this.confirmPassword).then(response=>{
                if (response.data) {
                    this.password = "";
                    this.confirmPassword = "";
                    this.close();
                    this.dialogue = "Password Changed!!";
                    this.content = "Password Changed Successfully."
                    this.isModal = true;
                }else{
                    this.errorMessage = "Unexpected Error!";
                }

              });

        }

      },
    },
});
</script>
<style>
.grad-back {
    background-color: var(--nccBlue);
    background-image: linear-gradient(160deg, var(--nccBlue) 0%, var(--lightBlue) 100%);
    border-bottom: 0 !important;
}

.title {
    color: var(--white);
    text-shadow: 5px 5px 10px var(--black);
}

.select-item {
    color: var(--white) !important;
}

.navbar-brand {
    cursor: pointer !important;
}

.navbar-green {
    background-color: #76bc21 !important;
}

.navbar-green .navbar-nav .nav-item .nav-link{
    color: white;
}

</style>