const se = {
  "1st": "1:a",
  "2nd": "2:a",
  "3rd": "3:a",
  "4th": "4:a",
  "5th": "5:a",
  "6th": "6:a",
  "7th": "7:a",
  "8th": "8:a",
  "9th": "9:a",
  "10th": "10:a",
  "11th": "11:a",
  "12th": "12:a",
  "13th": "13:a",
  "14th": "14:a",
  "15th": "15:a",
  "16th": "16:a",
  "17th": "17:a",
  "18th": "18:a",
  "19th": "19:a",
  "20th": "20:a",
  "21st": "21:a",
  "22nd": "22:a",
  "23rd": "23:a",
  "24th": "24:a",
  "25th": "25:a",
  "26th": "26:a",
  "27th": "27:a",
  "28th": "28:a",
  "29th": "29:a",
  "30th": "30:a",
  "31st": "31:a",
  "32nd": "32:a",
  "33rd": "33:a",
  "34th": "34:a",
  "35th": "35:a",
  "36th": "36:a",
  "37th": "37:a",
  "38th": "38:a",
  "39th": "39:a",
  "40th": "40:a",
  "41st": "41:a",
  "42nd": "42:a",
  "43rd": "43:a",
  "44th": "44:a",
  "45th": "45:a",
  "46th": "46:a",
  "47th": "47:a",
  "48th": "48:a",
  "49th": "49:a",
  "50th": "50:a",
  "51st": "51:a",
  "52nd": "52:a",
  //Common
  "update": "Uppdatering",
  "save": "Spara",
  "remove": "Avlägsna",
  "addNew": "Lägg till ny",
  "add": "Lägg till",
  "actions": "Handlingar",
  "ok": "OK",
  "cancel": "Avboka",
  "close": "Stänga",
  "all": "Allt",
  "edit": "Redigera",
  "delete": "Radera",
  "selectAll": "Välj alla",
  "swedish": "Svenska",
  "english": "Engelsk",
  "yes": "Ja",
  "no": "Nej",
  "password": "Lösenord",
  "confirmPassword": "Bekräfta Lösenord",
  "msgSureWantToDelete": "Är du säker på att du vill radera?",
  "msgSave": "Spara information framgångsrikt!",
  "msgUpdate": "Uppdatera information framgångsrikt!",
  "msgDelete": "Radera information framgångsrikt!",
  "confirmationDialog": "Bekräftelse dialog",
  "confirmDeleteContent": "Är du säker på att du vill ta bort?",
  "msgProvideAllData": "Vänligen ange alla data!",
  "home":"Hom",
  "templates":"Mallar",
  "roles":"Roller",
  "users":"Användare",
  "pages":"Sidor",
  "page":"Sida",
  "msgSelectParent": "Välj sida",
  "title": "Titel",
  "icon": "Ikon",
  "ordinal": "Ordinarie",
  "parent": "Förälder",
  "active": "Aktiva",
  "active (manual override)": "Aktiv (manuell åsidosättande)",
  "organizations": "Organisationer",
  "cost center permission": "Kostnadsställetillstånd",
  "access management": "Behörighets förvaltning",
  "add new": "Lägg till ny",
  "role name": "Rollnamn",
  "msgSelectRole": "Välj Roller",
  "role display name": "Rollvisningsnamn",
  "permission scope": "Tillståndsomfång",
  "user": "Användare",
  "full": "Full",
  "permission": "Lov",
  "permissions": "Behörigheter",
  "first": "Först",
  "last": "Sista",
  "name": "Namn",
  "email": "E-post",
  "select": "Välj",
  "welcome to sortera": "Välkommen till Sortera",
  "Company Name": "Företagsnamn",
  "Company Number": "Företagsnummer",
  "Profit Center Name": "Vinstcentralens namn",
  "Profit Center Code": "Profit Center Kod",
  "Business Unit Name": "Företagsenhetens namn",
  "Division": "Division",
  "Business Area Code": "Affärsriktnummer",
  "Business Area Name": "Affärsområdesnamn",
  "Country": "Land",
  "Source": "Källa",
  "Profit Center Key": "Nyckel för vinstcenter",
  "expand all": "Expandera alla",
  "collapse all": "Kollapsa alla",
  "organisation mapping": "Organisationskartläggning",
  "company": "Företag",
  "object": "Objekt",
  "system": "System",
  "source": "Källa",
  "destination": "Destination",
  "select destination": "Välj destination",
  "organisation": "Organisation",
  "organisations": "Organisations",
  "destination company": "Destinationsföretag",
  "destination profit center": "Destinationsresultatcentrum",
  "destination business area": "Affärsområde Destination",
  "select destination company": "Välj Destinationsföretag",
  "select destination profit center": "Välj Destinationsresultatcentrum",
  "select destination business area": "Välj Affärsområde Destination",
  "mapping": "Kartläggning",
  "master table": "Mästarbord",
  "hr": "HR",
  "paycodes": "Lönkoder",
  "time codes":"Tidskoder",
  "kpi": "KPI",
  "sales": "Försäljning",
  "sales stage probability": "Sannolikhet för försäljningsstadiet",
  "activity budget": "Aktivitetsbudget",
  "sales budget": "Försäljningsbudget",
  "operations": "Operationer",
  "vehicle efficiency": "Fordonets effektivitet",
  "select year": "Välj år",
  "select company": "Välj Företag",
  "select division": "Välj division",
  "division budget": "Avdelningens budget",
  "representative budget": "Representativ budget",
  "sales representative budget": "Säljare Budget",
  "Company": "Företag",
  "January": "Januari",
  "February": "Februari",
  "March": "Mars",
  "April": "April",
  "May": "Maj",
  "June": "Juni",
  "July": "Juli",
  "August": "Augusti",
  "September": "September",
  "October": "Oktober",
  "November": "November",
  "December": "December",
  "Division Budget Saved Successfully": "Divisionsbudget har sparats",
  "Invalid Inputs. Negative values not allowed": "Ogiltiga ingångar. Negativa värden är inte tillåtna",
  "Representative Budget Saved Successfully": "Representativ budget har sparats",
  "select representative": "välj Representant",
  "representative": "Representant",
  "activity type": "Aktivitetstyp",
  "customer type": "kundtyp",
  "Acitivity Budget Saved Successfully": "Aktivitetsbudget Har Sparats",
  "Stage Probabilities Saved Successfully": "Fassannolikheter Har Sparats Framgångsrikt",
  "stage": "Skede",
  "probability": "Sannolikhet",
  "probabilities": "Sannolikheter",
  "time code": "Tidskod",
  "time code name": "Tidskodsnamn",
  "absence time": "Frånvarotid",
  "available time": "Tillgänglig Tid",
  "include in FTE": "Inkludera i heltidsekvivalenter",
  "Time Codes Saved Successfully": "Tidskoder Har Sparats Framgångsrikt",
  "company name": "Företagsnamn",
  "company number": "Företagsnummer",
  "paycode": "Lönkod",
  "paycode name": "Lönkod Namn",
  "gross salary": "Bruttolön",
  "include in cost": "Inkludera i kostnaden",
  "benefit": "Förmån",
  "properties": "Egenskaper",
  "property": "Egendom",
  "total items": "Totalt antal artiklar",
  "select resource category": "Välj Resurskategori",
  "resource category code": "Resurskategorikod",
  "resource category name": "Resurskategorinamn",
  "resource type": "Resurstyp"
}

export default se;