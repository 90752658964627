import { defineComponent } from 'vue';
import AppBarComponent from '../organisms/AppBarComponent.vue';
import LoaderComponent from '../molecules/LoaderComponent.vue';
import { UserPage } from './layoutModel';
import store from '../../store'

let _lang: string = "EN";
let _multilang: { se: boolean, en: boolean } = {} as { se: boolean, en: boolean };

export default defineComponent({
  components: {
    LoaderComponent,
    AppBarComponent
  },
  mounted() {
    this.multilang = { en: true, se: false };
    this.lang = this.$i18n.locale.toUpperCase();
    this.isTest = location.href.includes('localhost') || location.href.includes('wonderful-sand') || location.href.includes('-test');
  },
  data() {
    return {
      isTest: false,
      isLoading:false,
      lang: _lang,
      multilang: _multilang,
    }
  },    
  computed: {
    userBasedPages(): UserPage[] {
        return store.state.userBasedPages;
    },
  },
  methods: {
    getPath(path: String) {
      return `${path}`;
    },
    languageChange(code: string) {
      this.$i18n.locale = code;
      this.lang = code.toUpperCase();
      this.multilang = { en: false, se: false };
      switch (code.toLowerCase()) {
        case 'en':
          this.multilang = { en: true, se: false };
          break;
        case 'se':
          this.multilang = { en: false, se: true };
          break;
      }
    },
    classAddRemove() {
      var bodyElement = document.body;
      var sidbarminiClass = bodyElement.classList.contains("sidebar-mini");
      var layoutfixedClass = bodyElement.classList.contains("layout-fixed");
      var registerpageClass = bodyElement.classList.contains("register-page");
      var loginpageClass = bodyElement.classList.contains("login-page");
      if (sidbarminiClass) bodyElement.classList.remove('sidebar-mini');
      if (layoutfixedClass) bodyElement.classList.remove('layout-fixed');
      if (registerpageClass) bodyElement.classList.remove('register-page');
      if (loginpageClass) bodyElement.classList.remove('login-page');
      bodyElement.classList.add("sidebar-mini");
      bodyElement.classList.add("layout-fixed");
    },
  }
});