import { UserPage } from "@/components/common/layoutModel";
import { createStore } from "vuex";

export default createStore({
  state: {
    user: "",
    accessToken:"",
    userBasedPages: [] as UserPage[]
  },
  mutations: {
    setUserBasedPages(state, userBasedPages: UserPage[]) {
      state!.userBasedPages = userBasedPages;
    },
    getUser(state, userData) {
      state!.user = userData;
    },
    setAccessToken(state,accessToken){
      state!.accessToken = accessToken;
    }
  },
  actions: {
    setUserBasedPages({ commit }, userBasedPages: UserPage[]) {
      try {
        commit('setUserBasedPages', userBasedPages);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    getUser({ commit }, user: string) {
      try {
        commit('getUser', user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    setAccessToken({commit}, accessToken:string){
      try{
        commit('setAccessToken',accessToken);
      }catch(error){
        console.log('Access Token error:',error);
      }
    },

  },
})