import { createApp } from 'vue';
import App from './App.vue';
import Toast from 'vue-toastification';
import router from './router/router';

import './plugins/fontawesome-free/css/all.min.css';
import './plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import './dist/css/adminlte.min.css';
import './plugins/jquery/jquery.min.js';
import './plugins/bootstrap/js/bootstrap.bundle.min.js';
import './dist/js/adminlte.min.js';
import './style.css';
import 'vue-toastification/dist/index.css';
import './assets/scripts/html2pdf.js';

import i18n from './i18';
import * as ej2 from '@syncfusion/ej2-base';
import { enableRipple } from '@syncfusion/ej2-base';
import lodash from 'lodash';
import store from "./store";
import { msalPlugin } from './plugins/msalPlugin';
import { msalInstance } from './plugins/auth-config';
import { CustomNavigationClient } from './router/NavigationClient';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { ToastPlugin } from "@syncfusion/ej2-vue-notifications";

ej2.validateLicense('ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5adERjWH5YcnVVQGdU');
enableRipple(true);

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const app = createApp(App);

app.config.errorHandler = (error,instance,info)=>{
  console.log(error);
  console.log(instance);
  console.log(info);
}

app
.use(store)
.use(ToastPlugin)
.use(Toast)
.use(router)
.use(msalPlugin, msalInstance)
.use(i18n)
.use(lodash)
.mount('#app')
