<template>
  <LoaderComponent/>
  <AppBarComponent :language="lang" :multilang="multilang" @click="languageChange" />
  <aside class="main-sidebar sidebar-light-primary elevation-4">
    <a class="brand-link" v-bind:class="{'background-yellow' : isTest, 'background-green' : !isTest }">
      <!--<img src="../../assets/images/pera.png" alt="Sortera Logo" class="brand-image img-circle elevation-3"
        style="opacity: .8">
      <span class="brand-text font-weight-light" style="font-size: 1.13rem;">Sortera
      </span> -->
      <img src="../../assets/images/sortera-logo.png" alt="Sortera Logo" class="logo-image" >
    </a>
    <div class="sidebar" style="padding:3px;" >
      <nav class="mt-2" >
        <ul class="nav nav-pills nav-sidebar flex-column nav-flat" data-widget="treeview" role="menu"
          data-accordion="false">
          <li class="nav-item menu-open">
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/">               
                  <a class="nav-link menu-content" :class="$route.path == '/' ? 'menu-active' : ''">
                      <i class="fas fa-home"></i>
                      <p class="page-item text-sm">{{ $t('home') }}</p>
                  </a>
                </router-link>  
<!--                 <router-link to="/pages">
                  <a class="nav-link" :class="$route.path == '/pages' ? 'active' : ''">
                      <i class="fas fa-book"></i>
                      <p class="page-item">{{ $t('pages') }}</p>
                  </a>      
                </router-link>  
                <router-link to="/roles">
                  <a class="nav-link" :class="$route.path == '/roles' ? 'active' : ''">
                      <i class="fas fa-list"></i>
                      <p class="page-item">{{ $t('roles') }}</p>
                  </a>      
                </router-link>                  
                <router-link to="/users">
                  <a class="nav-link" :class="$route.path == '/users' ? 'active' : ''">
                      <i class="fas fa-user"></i>
                      <p class="page-item">{{ $t('users') }}</p>
                  </a>      
                </router-link>    
                <router-link to="/organization">
                  <a class="nav-link" :class="$route.path == '/organization' ? 'active' : ''">
                      <i class="fas fa-building"></i>
                      <p class="page-item">{{ $t('Organizations') }}</p>
                  </a>      
                </router-link>       
                <router-link to="/costcenterpermission">
                  <a class="nav-link" :class="$route.path == '/costcenterpermission' ? 'active' : ''">
                      <i class="fas fa-lock"></i>
                      <p class="page-item">{{ $t('Cost Center Permissions') }}</p>
                  </a>      
                </router-link>   -->                            
              </li>
              <template v-for="(page, idx) in userBasedPages" :key="idx">
                <router-link v-if="page.childs && page.childs.length == 0" :to="page.pageUrl">
                  <a class="nav-link menu-content" :class="$route.path == getPath(page.pageUrl) ? 'menu-active' : ''">
                      <i :class="page.pageIcon"></i>
                      <p class="page-item text-sm">{{ $t(page.pageName.toLowerCase()) }}</p>
                  </a>
                </router-link>
                <li class="nav-item" v-else>
                  <a href="#" class="nav-link menu-content">
                    <i :class="page.pageIcon"></i>
                    <p class="page-item text-sm">
                      {{ $t(page.pageName.toLowerCase()) }}
                      <i class="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul class="nav nav-treeview" style="margin-left: 20px;" v-for="(childPage, childIdx) in page.childs"
                    :key="childIdx">
                    <router-link :to="childPage.pageUrl">
                      <a class="nav-link menu-content" :class="$route.path == getPath(childPage.pageUrl) ? 'menu-active' : ''">
                          <i :class="childPage.pageIcon"></i>
                          <p class="page-item text-sm">{{ $t(childPage.pageName.toLowerCase()) }}</p>
                      </a>
                    </router-link>
                  </ul>
                </li>
              </template>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>
<script lang="ts" src="./LayoutComponentService" ></script>
<style>
.page-item {
  padding-left: 0.5em;
}
.background-green {
  background-color: #76bc21
}
.logo-image {
  width: 58%;
}
.menu-active {
  background-color: #76bc21
}
.menu-content:hover{
  color: #76bc21 !important;
}

.menu-active p {
  color: white;
}

.menu-active i {
  color: white;
}


.background-yellow {
  background-color: #ffc107;
}

</style>

