import router from '../../router/router';
import store from '../../store';
import { AuthError} from '@azure/msal-browser';
import { msalInstance, loginRequest } from "../../plugins/auth-config";
import { AppConstants } from '../shared/appConstant';


const authService = {
    async login() {
        try {
            const authResult = await msalInstance.loginPopup();
            localStorage.setItem('user', authResult.account.username);
            store.dispatch('getUser', authResult.account.username);
            await this.getToken();
            console.log("login success");
            return authResult;
        } catch (error) {
            if (error instanceof AuthError && error.errorCode === 'user_cancelled') {
                console.warn('User canceled the authentication flow.');
                router.push('/login');
            } else {
                console.error('Authentication error:', error);
            }
            return null;
        }

    },
    async loginRedrict(){
        try{
            msalInstance.initialize().then(async () => {
                msalInstance.loginRedirect(loginRequest).then(() => {
                   this.getToken();
                   console.log("loginRedirect success");
                }).catch(() => {
                    
                }); 
            })
        }catch(exp){
            console.log("loginRedirect error: ", exp);
        }
    },
    async logout() {

        var authType = this.getAuthType();

        if("accessToken" in localStorage) localStorage.removeItem("accessToken");
        if("user" in localStorage) localStorage.removeItem("user");
        if("authType" in localStorage) localStorage.removeItem("authType");
        store.dispatch('getUser', '');


        if(authType?.includes("azure")){

            msalInstance.logoutRedirect().then(()=>{
                console.log("logout success");
            }).catch(() => {
                console.log("logout failed");
            });

        }
        else{
            window.location.reload();
        }

    },
    getActiveAccount() {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            return true;
        } else {
            return false;
        }
    },
    async getToken() {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length >= 1) {
            try{
                const REQUEST_SCOPES = {
                    scopes: AppConstants.Scopes
                };
                const account = { account: accounts[0] }
                const request = Object.assign(REQUEST_SCOPES, account);
                const tokenResponse = await msalInstance.acquireTokenSilent(request);
                localStorage.setItem('accessToken', tokenResponse.accessToken);
                localStorage.setItem('user', tokenResponse.account.username);
                store.dispatch('getUser', tokenResponse.account.username);
                store.dispatch('setAccessToken', tokenResponse.accessToken);


            }catch(exp){
                console.error("getToken:",exp);
                await this.loginRedrict();
            }
        }
        return null;
    },
    setAuthType(authType: string){
        return localStorage.setItem('authType', authType);
      },
    getAuthType(){
      return localStorage.getItem('authType');
    },
    isAuthenticated() {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            return true;
        } else {
            return false;
        }
    },
    getAuthToken(){
        return localStorage.getItem("accessToken");
    },
};

export default authService;