import moment from "moment";
export const show=()=>"block";
export const hide=()=>"none";
const helper = {
    getSwedishToNumeric(value: string | null){

        if (value == null || value == "" || value == undefined) {
          value = "0";
        }
  
        value = value.replace(',', ".").split(" ").join("");
  
        let numericValue: string = value;
   
        if (numericValue.length == 0) {
          numericValue = "0";
        }
  
        return parseFloat(numericValue);
  
    },
    getSwedishNumber(value: string | null){

        if (value == null || value == '' || value == undefined) {
          return "";
        }
       
        var minusValue = value.includes('-');
  
        value = value.replace('-','').replace(',', ".").split(" ").join("");
  
        var partsArr =  value.split(".");
  
        var numericValue = "";
  
        var integerPartReversed = parseFloat(partsArr[0]).toString().split("").reverse();
        var integerPartReversedFormated = "";
        
        integerPartReversed.map((c, i) => {
  
           if(i > 0 && i%3 == 2 &&  integerPartReversed.length-1  != i){
             integerPartReversedFormated += c+" ";
           }
           else{
             integerPartReversedFormated += c;
           }
  
        });
  
        numericValue = integerPartReversedFormated.split("").reverse().join("");
  
        if(value.includes(".")){
          numericValue += ",";
        }
  
        if(partsArr.length > 1){
          numericValue += (partsArr[1].length > 2 ? partsArr[1][0]+partsArr[1][1] : partsArr[1]);
        }
  
        if(minusValue){
          numericValue = "-"+numericValue;
        }
       
        return numericValue;
    
    },
    camelCase(str: string) {
        // Using replace method with regEx
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '')
    },
    precisionRound(number: number, precision: number) {
        const factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    },
    roundToTwoDecimalPlaces(num: number) {
        const factor = Math.pow(10, 2);
        // Round the number to two decimal places
        const roundedNum = Math.round(num * factor) / factor;

        // Convert the rounded number to a string
        const numStr = roundedNum.toString();

        // Split the number into its integer and decimal parts
        const [integerPart, decimalPart] = numStr.split('.');

        let formattedDecimalPart = `00`;

        if (decimalPart) {
            // If the decimal part is a single digit, add a leading zero
            formattedDecimalPart = decimalPart.length === 1 ? `${decimalPart}0` : decimalPart;
        }
        if (Number(integerPart) < 0) {
            const formattedIntegerPart = (Number(integerPart) * -1);

            return `(${formattedIntegerPart},${formattedDecimalPart} kr)`;
        } else {
            return `${integerPart},${formattedDecimalPart} kr`;
        }

    },
    // percentFormatter(amount: number) {
    //     if (!amount) {
    //         amount = 0;
    //     }
    //     const options = {
    //         style: 'decimal',
    //         useGrouping: false, // Enables thousands separators
    //         minimumFractionDigits: 2, // Minimum number of decimal places
    //         maximumFractionDigits: 2, // Maximum number of decimal places
    //         minimumIntegerDigits: 1, // Minimum number of integer digits
    //         // Customize the separators:
    //         //groupSeparator: ' ', // Thousands separator
    //         decimalSeparator: ',', // Decimal separator
    //     };
    //     const formattedNumber = amount.toLocaleString('fr-FR', options)
    //     return formattedNumber + '%';

    // },
    // percentWithoutSignFormatter(amount: number) {
    //     if (!amount) {
    //         amount = 0;
    //     }
    //     const options = {
    //         style: 'decimal',
    //         useGrouping: false, // Enables thousands separators
    //         minimumFractionDigits: 2, // Minimum number of decimal places
    //         maximumFractionDigits: 2, // Maximum number of decimal places
    //         minimumIntegerDigits: 1, // Minimum number of integer digits
    //         // Customize the separators:
    //         //groupSeparator: ' ', // Thousands separator
    //         decimalSeparator: ',', // Decimal separator
    //     };
    //     const formattedNumber = amount.toLocaleString('fr-FR', options)
    //     return formattedNumber;

    // },
    // sekFormatter(amount: number) {
    //     if (!amount) {
    //         amount = 0;
    //     }
    //     const options = {
    //         style: 'decimal',
    //         useGrouping: true, // Enables thousands separators
    //         minimumFractionDigits: 2, // Minimum number of decimal places
    //         maximumFractionDigits: 2, // Maximum number of decimal places
    //         minimumIntegerDigits: 1, // Minimum number of integer digits
    //         // Customize the separators:
    //         groupSeparator: ' ', // Thousands separator
    //         decimalSeparator: ',', // Decimal separator
    //     };
    //     if (amount < 0) {
    //         amount = amount * -1;
    //         const formattedNumber = amount.toLocaleString('fr-FR', options)
    //         return '(' + formattedNumber + ''
    //     } else {
    //         const formattedNumber = amount.toLocaleString('fr-FR', options)
    //         return formattedNumber;
    //     }
    // },
    // sekFormatterWithOutThousandSeperator(amount: number) {
    //     if (!amount) {
    //         amount = 0;
    //     }
    //     const options = {
    //         style: 'decimal',
    //         useGrouping: false, // Enables thousands separators
    //         minimumFractionDigits: 2, // Minimum number of decimal places
    //         maximumFractionDigits: 2, // Maximum number of decimal places
    //         minimumIntegerDigits: 1, // Minimum number of integer digits
    //         // Customize the separators:
    //         //groupSeparator: ' ', // Thousands separator
    //         decimalSeparator: ',', // Decimal separator
    //     };

    //     const formattedNumber = amount.toLocaleString('fr-FR', options)
    //     return formattedNumber;

    // },
    isNullOrEmpty(value: any) {
        return value === undefined || value === null || value === '';
    },
    generateGUIDWithoutHyphens():string {
        return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    },
    getDateTimeUTC(){
        const currentDate = new Date(new Date().toISOString().slice(0,-1));
        return moment(currentDate).utc(true);
    },
    getDurationAsSentence(startDate:string,endDate:string):string{
        const start = moment(startDate).utc(true);
        const end = moment(endDate).utc(true);
        const duration = moment.duration(end.diff(start));
        if(duration.asYears() >= 1){
            return `${Math.floor(duration.asYears())} ${duration.asYears() >1?'years':'year'} ${'ago'}`;
        }
        if(duration.asMonths() >= 1){
            return `${Math.floor(duration.asMonths())} ${duration.asMonths() >1?'months':'month'} ${'ago'}`;
        }
        if(duration.asWeeks() >= 1){
            return `${Math.floor(duration.asWeeks())} ${duration.asWeeks() >1?'weeks':'week'} ${'ago'}`;
        }
        if(duration.asDays() >= 1){
            return `${Math.floor(duration.asDays())} ${duration.asDays() >1?'days':'day'} ${'ago'}`;
        }
        if(duration.asHours() >= 1){
            return `${Math.floor(duration.asHours())} ${duration.asHours() >1?'hours':'hour'} ${'ago'}`;
        }
        if(duration.asMinutes() >= 1){
            return `${Math.floor(duration.asMinutes())} ${duration.asMinutes() >1?'minutes':'minute'} ${'ago'}`;
        }
        return 'justNow';
    },
    toLowerToUpperCase(inputString:string){
        const words = inputString.split(' ');

        const camelCasedWords = words.map((word: any, index: any) => {
            if (index === 0) {
                return word.toLowerCase();
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
        });

        const camelCasedString = camelCasedWords.join('');

        return camelCasedString;
    },
    getSizeNumberAsSentence(bytes:number){
        let sizeUnit = "KB";
        let size = bytes / 1024; // KB

        if (size > 1024) {
            sizeUnit = "MB";
            size = size / 1024; // MB
        }

        if (size > 1024) {
            sizeUnit = "GB";
            size = size / 1024; // GB
        }

        if (size == 0) {
            return "";
        }

      return `${size.toFixed(2)} ${sizeUnit}`;
    },
    delay(duration: number) {
        return new Promise((resolve) => setTimeout(resolve, duration));
    },
    pad(number: number, length: number) {
        let str = "" + number;
        while (str.length < length) {
          str = "0" + str;
        }
        return str;
    },
    getReplacedValidMeta(input: string){
        const data = input;
        const invalidVariableRegex = /[^a-zA-Z$]|^[0-9]|[^0-9a-zA-Z$]/g;
        const replace = data.replace(invalidVariableRegex, () => String(""));
        //let index:number=1;
        return replace;
    },
    getReplaceValidTag(inputString:string) {
        const validCharactersPattern = /[^a-zA-Z0-9\s+\-./:=_]/g;
      
        const sanitizedString = inputString.replace(validCharactersPattern, ' ');
      
        return sanitizedString;
    },
    isValidFolderName(folderName: string){
        const folderNameRegex: RegExp = /^[^\\/:\\*\\?"<>\\|]+$/;
        return folderNameRegex.test(folderName);
    },
    elementVisibility(element: HTMLElement|undefined,visible:string) {
        if (element) {
            return element.style.display = visible;
        }
    }
}

export default helper;