export  class AppConstants{

    static readonly API_URL = location.href.includes('localhost') ? 
      "https://localhost:61844" : 
          location.href.includes('wonderful-sand') || location.href.includes('-test') ? 
              "https://as-test-datainitiative-api.azurewebsites.net" : "https://as-datainititive-api-prod-fncmane4bueqapbb.swedencentral-01.azurewebsites.net";

      static readonly CLIENT_URL = location.href.includes('localhost') ? 
        "http://localhost:8080" : 
            location.href.includes('wonderful-sand') || location.href.includes('-test') ?
              "https://dapp-test.sortera.org" : "https://dapp.sortera.org";
              //"http://wonderful-sand-00867a303.5.azurestaticapps.net" : "https://delightful-river-07d17ab03.5.azurestaticapps.net";

      

    static readonly LocalhostRedirectUri = AppConstants.API_URL;
    static readonly LocalhostPostLogoutRedirectUri = `${AppConstants.CLIENT_URL}/login/`; 

    static readonly TenantId = 
      location.href.includes('localhost') ? '0ed11b7c-74bd-478f-8a21-38a7f2e78a5e' : '6e4277b8-d8e0-4674-ba2e-b043ad84cc9e';
      //'6e4277b8-d8e0-4674-ba2e-b043ad84cc9e'; //sortera
      //'0ed11b7c-74bd-478f-8a21-38a7f2e78a5e'; //numberskills

    static readonly ClientId = 
    location.href.includes('localhost') ? '34a2a321-be19-41d9-84a0-f64db3673de0' : '0438c947-cceb-4f15-91e1-7d62ccddd6a7';
      //'0438c947-cceb-4f15-91e1-7d62ccddd6a7'; //sortera
      //'34a2a321-be19-41d9-84a0-f64db3673de0'; //numberskills

    static readonly Authority = `https://login.microsoftonline.com/${AppConstants.TenantId}`;    
          
    static readonly PostLogoutRedirectUri = `${AppConstants.CLIENT_URL}/login/`;
    static readonly Scopes = [
      `api://${AppConstants.ClientId}/access_as_user`
    ];

    static readonly NavigateToRequestUrl = false;
    static readonly CacheLocation = 'localStorage';
    static readonly AllowRedirectInIframe = true;
    static readonly AzureLoginEnabled = true;
    static readonly BasicLoginEnabled = false;
}