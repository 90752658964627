import axios, { AxiosResponse } from 'axios';
import toasterService from './toasterService';
import authService from './auth-service';
import preLoader from './preLoader';

const setBaseURL = (baseUrl: string) => {
  axios.defaults.baseURL = baseUrl;
}

axios.interceptors.request.use(
  async (config) => {

    const accessToken = authService.getAuthToken();
    let authType =authService.getAuthType();

    if (accessToken) {
      config.headers.Authorization  = `Bearer ${accessToken}`;
      config.headers.AuthType  = authType;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      preLoader.hide();
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  }, async (error: any) => {
    preLoader.hide();
    if (error.response) {
      let responseModel;
      switch (error.response.status) {
        case 400:
          responseModel = JSON.parse(JSON.stringify(error.response.data));
          if (responseModel && responseModel.errorMessage) {
            toasterService.warn(`${responseModel.errorMessage}`);
          } else {
            toasterService.warn(`${responseModel.title}`);
          }
          break;
        case 401:
          authService.loginRedrict();
          toasterService.error("You're Unauthorized!.");
          return Promise.resolve(null);
        case 403:
          toasterService.error("Forbidden!!");
          break;
        case 404:
          toasterService.error("Not Found!!");
          break;
        case 500:
          toasterService.error(`Server Error!!${error.message}`);
          break;
      }

      return Promise.reject(error.response);
    }
  }
);


const responseBody = <T>(response: AxiosResponse<T>) => response && response.data;

const httpClient = {

  setBaseUrl: (baseUrl: string) => setBaseURL(baseUrl),


  async get<T>(url: string, headers: {} = {}, isLoader:boolean=true){
    if(isLoader) preLoader.show();

    // fetch(url, {
    //   method: 'GET',
    //   headers: {
    //     'Authorization': `Bearer ${token}`,
    //   }
    // })
    // .then(response => response.json())
    // .then(data => console.log(data))
    // .catch(error => console.log(error));

    
    // const response = await axios
    //   .get<T>(url, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     maxRedirects: 0
    //   });
      


    const response = await axios
      .get<T>(url, headers);
      
    return responseBody(response);
  },


  async post<T>(url: string, body: {},headers: {} = {},isLoader:boolean=true) {
    if(isLoader) preLoader.show();
    const response = await axios
      .post<T>(url, body, headers);
    return responseBody(response);
  },


  async put<T>(url: string, body: {}, headers: {} = {}, isLoader:boolean=true){
    if(isLoader) preLoader.show();
    const response = await axios
      .put<T>(url, body, headers);
    return responseBody(response);
  },


  async delete<T>(url: string, headers: {} = {}, isLoader:boolean=true) {
    if(isLoader) preLoader.show();
    const response = await axios
      .delete<T>(url, headers);
    return responseBody(response);
  }

}

export default httpClient;