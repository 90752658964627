import { RouteLocationNormalized, Router } from "vue-router";
import { msalInstance, loginRequest } from "../plugins/auth-config";
import { InteractionType, PopupRequest, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import authService from "../services/common/auth-service";
import modal from '../components/common/modalManage';
import {AppConstants} from '../services/shared/appConstant';
import loadMenuService from '../services/common/load-menu-service';

export function registerGuard(router: Router) {
    router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        if(to.path.includes("login")){
            router.push("/");
        }
        if (to.meta.requiresAuth) {

            if(!authService.getAuthType()){

                //var constants = new AppConstants();

                console.log(AppConstants.AzureLoginEnabled);

                if(AppConstants.AzureLoginEnabled  && AppConstants.BasicLoginEnabled){
                    modal.Open('LoginMethodModal');
                }
                else if(AppConstants.AzureLoginEnabled){
                    authService.setAuthType('azure');
                }
                else if(AppConstants.BasicLoginEnabled){
                    authService.setAuthType('basicUnconfirmed');
                }
                
            }

            if(authService.getAuthType() == 'azure'){

                const request = {
                    ...loginRequest,
                    redirectStartPage: to.path
                }

               await msalInstance.initialize().then(async () => {                    
                    const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request);
                    await authService.getToken();

                    if(to.path==='/'){
                        return "/"
                    }else{
                        loadMenuService.loadUserBasedPages();
                        return shouldProceed;
                    }
                })
                
            }
            else if(authService.getAuthType() == 'basicUnconfirmed'){
                modal.Open('CustomLoginModal');
                authService.setAuthType('');
            }
            else if(authService.getAuthType() == 'basic'){
                loadMenuService.loadUserBasedPages();
            }
        }

        return true;
    });
}

export async function isAuthenticated(instance: PublicClientApplication, interactionType: InteractionType, loginRequest: PopupRequest | RedirectRequest): Promise<boolean> {

    
        // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
        return instance.handleRedirectPromise().then(() => {
            const accounts = instance.getAllAccounts();
            if (accounts.length > 0) {
                return true;
            }
            else if(AppConstants.AzureLoginEnabled  && ( !AppConstants.BasicLoginEnabled || confirm('Are you sure to continue with Azure Login?'))){

                // User is not signed in and attempting to access protected route. Sign them in.
                if (interactionType === InteractionType.Popup) {
                    return instance.loginPopup(loginRequest).then(() => {
                        return true;
                    }).catch(() => {
                        return false;
                    })
                } else if (interactionType === InteractionType.Redirect) {
                    return instance.loginRedirect(loginRequest).then(() => {
                        return true;
                    }).catch(() => {
                        return false;
                    });
                }

            }
            else{
                authService.setAuthType('');
                window.location.reload();
            }

            return false;
        }).catch(() => {
            return false;
        });

}